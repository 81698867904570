<template>
	<div>
		<div>
			<div>
				<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
					<font-awesome-icon icon="arrow-left"/>
					Vissza
				</a>
			</div>
			<div class="text-center">
				<p><br/>Apache License<br/>Version 2.0, January 2004<br/>http://www.apache.org/licenses/<br/><br/>&nbsp;&nbsp; TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION<br/><br/>&nbsp;&nbsp; 1. Definitions.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "License" shall mean the terms and conditions for use, reproduction,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and distribution as defined by Sections 1 through 9 of this document.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Licensor" shall mean the copyright owner or entity authorized by<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the copyright owner that is granting the License.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Legal Entity" shall mean the union of the acting entity and all<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; other entities that control, are
					controlled by, or are under common<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; control with that entity. For the purposes of this definition,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "control" means (i) the power, direct or indirect, to cause the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; direction or management of such entity, whether by contract or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; otherwise, or (ii) ownership of fifty percent (50%) or more of the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; outstanding shares, or (iii) beneficial ownership of such entity.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "You" (or "Your") shall mean an individual or Legal Entity<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; exercising permissions granted by this License.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Source" form shall mean the
					preferred form for making modifications,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; including but not limited to software source code, documentation<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; source, and configuration files.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Object" form shall mean any form resulting from mechanical<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; transformation or translation of a Source form, including but<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not limited to compiled object code, generated documentation,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and conversions to other media types.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Work" shall mean the work of authorship, whether in Source or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Object form, made available under the License, as indicated by a<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					copyright notice that is included in or attached to the work<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (an example is provided in the Appendix below).<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Derivative Works" shall mean any work, whether in Source or Object<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; form, that is based on (or derived from) the Work and for which the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; editorial revisions, annotations, elaborations, or other modifications<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; represent, as a whole, an original work of authorship. For the purposes<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of this License, Derivative Works shall not include works that remain<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; separable from, or merely link (or bind by name) to the interfaces of,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					the Work and Derivative Works thereof.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Contribution" shall mean any work of authorship, including<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the original version of the Work and any modifications or additions<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to that Work or Derivative Works thereof, that is intentionally<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; submitted to Licensor for inclusion in the Work by the copyright owner<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or by an individual or Legal Entity authorized to submit on behalf of<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the copyright owner. For the purposes of this definition, "submitted"<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; means any form of electronic, verbal, or written communication sent<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					to the Licensor or its representatives, including but not limited to<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; communication on electronic mailing lists, source code control systems,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and issue tracking systems that are managed by, or on behalf of, the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Licensor for the purpose of discussing and improving the Work, but<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; excluding communication that is conspicuously marked or otherwise<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; designated in writing by the copyright owner as "Not a Contribution."<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Contributor" shall mean Licensor and any individual or Legal Entity<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; on behalf of whom a Contribution has been received by
					Licensor and<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; subsequently incorporated within the Work.<br/><br/>&nbsp;&nbsp; 2. Grant of Copyright License. Subject to the terms and conditions of<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; this License, each Contributor hereby grants to You a perpetual,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; worldwide, non-exclusive, no-charge, royalty-free, irrevocable<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; copyright license to reproduce, prepare Derivative Works of,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; publicly display, publicly perform, sublicense, and distribute the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Work and such Derivative Works in Source or Object form.<br/><br/>&nbsp;&nbsp; 3. Grant of Patent License. Subject to the terms and conditions of<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					this License, each Contributor hereby grants to You a perpetual,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; worldwide, non-exclusive, no-charge, royalty-free, irrevocable<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (except as stated in this section) patent license to make, have made,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use, offer to sell, sell, import, and otherwise transfer the Work,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; where such license applies only to those patent claims licensable<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; by such Contributor that are necessarily infringed by their<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contribution(s) alone or by combination of their Contribution(s)<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with the Work to which such Contribution(s) was submitted. If You<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					institute patent litigation against any entity (including a<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; cross-claim or counterclaim in a lawsuit) alleging that the Work<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or a Contribution incorporated within the Work constitutes direct<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or contributory patent infringement, then any patent licenses<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; granted to You under this License for that Work shall terminate<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as of the date such litigation is filed.<br/><br/>&nbsp;&nbsp; 4. Redistribution. You may reproduce and distribute copies of the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Work or Derivative Works thereof in any medium, with or without<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; modifications, and in Source or
					Object form, provided that You<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; meet the following conditions:<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a) You must give any other recipients of the Work or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Derivative Works a copy of this License; and<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b) You must cause any modified files to carry prominent notices<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; stating that You changed the files; and<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (c) You must retain, in the Source form of any Derivative Works<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that You distribute, all copyright, patent, trademark, and<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					attribution notices from the Source form of the Work,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; excluding those notices that do not pertain to any part of<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Derivative Works; and<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (d) If the Work includes a "NOTICE" text file as part of its<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; distribution, then any Derivative Works that You distribute must<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; include a readable copy of the attribution notices contained<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; within such NOTICE file, excluding those notices that do not<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; pertain
					to any part of the Derivative Works, in at least one<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of the following places: within a NOTICE text file distributed<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as part of the Derivative Works; within the Source form or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; documentation, if provided along with the Derivative Works; or,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; within a display generated by the Derivative Works, if and<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wherever such third-party notices normally appear. The contents<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of the NOTICE file are for informational purposes only and<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					do not modify the License. You may add Your own attribution<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; notices within Derivative Works that You distribute, alongside<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or as an addendum to the NOTICE text from the Work, provided<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that such additional attribution notices cannot be construed<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as modifying the License.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You may add Your own copyright statement to Your modifications and<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; may provide additional or different license terms and conditions<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for use, reproduction, or distribution of
					Your modifications, or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for any such Derivative Works as a whole, provided Your use,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; reproduction, and distribution of the Work otherwise complies with<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the conditions stated in this License.<br/><br/>&nbsp;&nbsp; 5. Submission of Contributions. Unless You explicitly state otherwise,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any Contribution intentionally submitted for inclusion in the Work<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; by You to the Licensor shall be under the terms and conditions of<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; this License, without any additional terms or conditions.<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notwithstanding the above, nothing herein shall supersede or
					modify<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the terms of any separate license agreement you may have executed<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with Licensor regarding such Contributions.<br/><br/>&nbsp;&nbsp; 6. Trademarks. This License does not grant permission to use the trade<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; names, trademarks, service marks, or product names of the Licensor,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; except as required for reasonable and customary use in describing the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; origin of the Work and reproducing the content of the NOTICE file.<br/><br/>&nbsp;&nbsp; 7. Disclaimer of Warranty. Unless required by applicable law or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; agreed to in writing, Licensor provides the Work (and each<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					Contributor provides its Contributions) on an "AS IS" BASIS,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; implied, including, without limitation, any warranties or conditions<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PARTICULAR PURPOSE. You are solely responsible for determining the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; appropriateness of using or redistributing the Work and assume any<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; risks associated with Your exercise of permissions under this License.<br/><br/>&nbsp;&nbsp; 8. Limitation of Liability. In no event and under no legal theory,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					whether in tort (including negligence), contract, or otherwise,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; unless required by applicable law (such as deliberate and grossly<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; negligent acts) or agreed to in writing, shall any Contributor be<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; liable to You for damages, including any direct, indirect, special,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; incidental, or consequential damages of any character arising as a<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; result of this License or out of the use or inability to use the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Work (including but not limited to damages for loss of goodwill,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; work stoppage, computer failure or malfunction, or any and all<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					other commercial damages or losses), even if such Contributor<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; has been advised of the possibility of such damages.<br/><br/>&nbsp;&nbsp; 9. Accepting Warranty or Additional Liability. While redistributing<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Work or Derivative Works thereof, You may choose to offer,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and charge a fee for, acceptance of support, warranty, indemnity,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or other liability obligations and/or rights consistent with this<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; License. However, in accepting such obligations, You may act only<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; on Your own behalf and on Your sole responsibility, not on behalf<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of any other
					Contributor, and only if You agree to indemnify,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; defend, and hold each Contributor harmless for any liability<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; incurred by, or claims asserted against, such Contributor by reason<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of your accepting any such warranty or additional liability.<br/><br/>&nbsp;&nbsp; END OF TERMS AND CONDITIONS<br/><br/>&nbsp;&nbsp; APPENDIX: How to apply the Apache License to your work.<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To apply the Apache License to your work, attach the following<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; boilerplate notice, with the fields enclosed by brackets "[]"<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; replaced with your own identifying information. (Don't include<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					the brackets!)&nbsp; The text should be enclosed in the appropriate<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; comment syntax for the file format. We also recommend that a<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; file or class name and description of purpose be included on the<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; same "printed page" as the copyright notice for easier<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; identification within third-party archives.<br/><br/>&nbsp;&nbsp; Copyright [yyyy] [name of copyright owner]<br/><br/>&nbsp;&nbsp; Licensed under the Apache License, Version 2.0 (the "License");<br/>&nbsp;&nbsp; you may not use this file except in compliance with the License.<br/>&nbsp;&nbsp; You may obtain a copy of the License at<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					http://www.apache.org/licenses/LICENSE-2.0<br/><br/>&nbsp;&nbsp; Unless required by applicable law or agreed to in writing, software<br/>&nbsp;&nbsp; distributed under the License is distributed on an "AS IS" BASIS,<br/>&nbsp;&nbsp; WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.<br/>&nbsp;&nbsp; See the License for the specific language governing permissions and<br/>&nbsp;&nbsp; limitations under the License.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ApacheLicenseView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	}
}
</script>

<style scoped>

</style>
